import Highcharts from "highcharts";
import { chartBackgroundColor, controlCategoryDataLabelFormatter } from "./globals";
import { riskColorRangeRedToGreen } from "./risk_colors"

document.addEventListener('turbolinks:load', () => {
  let stops = [
    [0.0, riskColorRangeRedToGreen[10]],
    [0.1, riskColorRangeRedToGreen[20]],
    [0.2, riskColorRangeRedToGreen[30]],
    [0.3, riskColorRangeRedToGreen[40]],
    [0.4, riskColorRangeRedToGreen[50]],
    [0.5, riskColorRangeRedToGreen[60]],
    [0.6, riskColorRangeRedToGreen[70]],
    [0.7, riskColorRangeRedToGreen[80]],
    [0.8, riskColorRangeRedToGreen[90]],
    [0.9, riskColorRangeRedToGreen[100.01]]
  ]

  let charts: NodeListOf<HTMLElement> = document.querySelectorAll("[data-chart='control_categories']")

  if (charts) {
    Array.from(charts).forEach((chart) => {
      const averageScore = JSON.parse(chart.dataset.averageScore)
      const score = JSON.parse(chart.dataset.score)
      const showComparativeData = averageScore == false ? false : true

      Highcharts.chart(chart.id, {
        chart: {
          backgroundColor: chartBackgroundColor,
          spacing: [0, 0, 0, 0],
          type: "solidgauge",
          height: 300
        },
        title: null,
        yAxis: {
          plotBands: {
            from: averageScore == false ? 0 : averageScore,
            to: averageScore == false ? 0 : averageScore + 1,
            color: 'lightgray',
            borderWidth: 0,
            borderColor: 'black',
            thickness: 0,
            zIndex: 0,
          },
          lineWidth: 0,
          labels: {
            enabled: false,
          },
          minorTickInterval: null,
          min: 0,
          max: 100,
          tickWidth: 0,
          stops: stops,
        },
        tooltip: {
          enabled: false,
        },
        pane: {
          background: [{
            backgroundColor: "#E8ECF5",
            borderWidth: 0,
            innerRadius: "90%",
            outerRadius: "100%",
          }],
        },
        plotOptions: {
          solidgauge: {
            innerRadius: "90%",
            animation: false,
          }
        },
        series: [{
          data: [score],
          dataLabels: {
            y: -50,
            borderWidth: 0,
            formatter: function () {
              return controlCategoryDataLabelFormatter(this, averageScore)
            },
            useHTML: true,
            style: {
              fontSize: "1.5em",
              fontWeight: "normal",
              "text-anchor": "middle",
            }
          },
        }]
      });
    })
  }
});
