import Highcharts, { chart } from "highcharts";
import { barChartBorderRadius, columnTooltipFormatter, crosshairColor, crpBarChartYAxisOptions, lineColor, plotOptionsBarBorderColor, plotOptionsBarBorderWidth, plotOptionsBarGrouping, plotOptionsBarShowInLegend, plotOptionsBarThreshold, xAxisLabelStyle, xAxisTickWidth } from "./globals";
import { riskZonesGreenToRed } from "./risk_colors";

document.addEventListener('turbolinks:load', () => {
  let charts: NodeListOf<HTMLElement> = document.querySelectorAll("[data-chart='domain_risk_profile']")

  if (charts) {
    Array.from(charts).forEach((chart) => {
      const chartData = JSON.parse(chart.dataset.domainCrp)
      const comparativeCrpData = JSON.parse(chart.dataset.comparativeCrpData)
      const domainPercentiles = JSON.parse(chart.dataset.domainPercentiles)
      const resourceNames = chartData.values.map(value => value.resource_name)
      const scores = chartData.values.map(value => value.y)

      Highcharts.chart(chart.id, {
        chart: {
          backgroundColor: null,
          type: "bar",
          height: 250,
        },
        title: {
          text: ""
        },
        xAxis: [{
          labels: {
            style: xAxisLabelStyle,
          },
          lineColor: lineColor,
          tickColor: lineColor,
          categories: chartData.categories,
          crosshair: {
            color: crosshairColor
          },
          tickWidth: xAxisTickWidth,
        }, {
          visible: domainPercentiles ? true : false,
          opposite: true,
          categories: domainPercentiles ? domainPercentiles : [],
          labels: {
            style: {
              fontSize: '12px',
              fontWeight: 'bold'
            }
          },
          lineWidth: 0,
          tickWidth: 0,
          title: {
            text: 'Percentile Score',
            style: {
              color: 'gray',
              fontSize: '14px',
              fontWeight: 'bold'
            }
          }
        }],
        yAxis: crpBarChartYAxisOptions,
        tooltip: {
          enabled: true,
          formatter: function () {
            return columnTooltipFormatter(this, resourceNames, comparativeCrpData, scores);
          }
        },
        plotOptions: {
          bar: {
            animation: false,
            borderColor: plotOptionsBarBorderColor,
            borderWidth: plotOptionsBarBorderWidth,
            grouping: plotOptionsBarGrouping,
            showInLegend: plotOptionsBarShowInLegend,
            threshold: plotOptionsBarThreshold,
            zones: riskZonesGreenToRed
          },
          series: {
            borderRadiusTopLeft: barChartBorderRadius,
            borderRadiusTopRight: barChartBorderRadius,
            cursor: 'pointer',
            point: {
              events: {
                click: function () {
                  location.href = this.options.url;
                }
              }
            }
          }
        },
        series: [{
          name: "container",
          showInLegend: false,
          animation: false,
          data: chartData.container,
          tooltip: {
            followPointer: true
          }
        }, {
          xAxis: 1,
          name: "risk",
          showInLegend: false,
          data: chartData.values,
          states: {
            inactive: {
              opacity: 1
            },
            hover: {
              xAxis: 1,
              enabled: true
            }
          }
        }, {
          name: '',
          visible: comparativeCrpData,
          data: comparativeCrpData,
          pointStart: 0,
          pointInterval: 1,
          lineWidth: 0,
          type: 'line',
          color: 'black',
          showInLegend: false,
          states: {
            hover: {
              enabled: false,
            }
          },
          marker: {
            fillColor: 'lightgray',
            lineWidth: 1,
            lineColor: 'black'
          }
        }]
      });
    })
  }
})
