import { riskColorRangeRedToGreen } from "./risk_colors";
import { riskZoneLineColor, primaryGraphColor, positionDial } from "./globals";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import SolidGauge from "highcharts/modules/solid-gauge";
HighchartsMore(Highcharts);
SolidGauge(Highcharts);

let riskGaugeOptions = [];
for (let key in riskColorRangeRedToGreen) {
  riskGaugeOptions.push({
    y: 10,
    name: "",
    color: riskColorRangeRedToGreen[key],
  });
}

document.addEventListener("turbolinks:load", () => {
  let charts: NodeListOf<HTMLElement> = document.querySelectorAll(
    "[data-chart='risk_profile']",
  );

  if (charts) {
    Array.from(charts).forEach((chart) => {
      let crpValue = Math.round(parseFloat(chart.dataset.riskProfileCrp));
      let comparativeCrpValue = Math.round(
        parseFloat(chart.dataset.riskProfileComparativeValue),
      );

      Highcharts.chart(chart.id, {
        title: false,
        subtitle: false,
        pane: {
          startAngle: -90,
          endAngle: 90,
          background: {
            borderWidth: 0,
            backgroundColor: "transparent",
            innerRadius: "90%",
            outerRadius: "100%",
            shape: "arc",
          },
        },
        yAxis: [
          {
            lineWidth: 0,
            min: 0,
            max: 90,
            minorTickLength: 0,
            tickWidth: 0,
            labels: {
              enabled: false,
            },
          },
        ],
        tooltip: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: false,
            },
            startAngle: -90,
            endAngle: 90,
          },
          gauge: {
            dataLabels: {
              enabled: false,
            },
            pivot: {
              radius: 11,
              borderWidth: 8,
              backgroundColor: riskZoneLineColor,
              borderColor: primaryGraphColor,
            },
            dial: {
              radius: "100%",
              backgroundColor: primaryGraphColor,
              borderColor: primaryGraphColor,
              baseWidth: 13,
              baseLength: "5%",
              rearLength: "5%",
            },
          },
          series: {
            states: {
              inactive: {
                opacity: 1,
              },
              hover: {
                enabled: false,
              },
            },
          },
        },
        series: [
          {
            animation: false,
            type: "pie",
            innerSize: "65%",
            data: riskGaugeOptions,
          },
          {
            animation: false,
            type: "pie",
            size: "68%",
            innerSize: "91%",
            color: "white",
            data: [
              {
                y: 100,
                name: "",
                color: riskZoneLineColor,
              },
            ],
          },
          {
            animation: false,
            type: "gauge",
            data: [positionDial(crpValue)],
            dial: {
              rearLength: 0,
            },
          },
          {
            animation: false,
            type: "gauge",
            visible: comparativeCrpValue
              ? "AVG SCORE: " + comparativeCrpValue
              : false,
            data: [positionDial(comparativeCrpValue)],
            dial: {
              backgroundColor: "#909090",
              baseWidth: 6,
              borderWidth: 0.5,
              rearLength: 0,
            },
          },
        ],
      });
    });
  }
});
