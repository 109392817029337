import { riskColorRangeRedToGreen, riskZonesGreenToRed } from "./risk_colors";
import * as Highcharts from 'highcharts';

var riskThresholds = {
  low: 0,
  medium: 20,
  high: 50
}

var riskLabels = {
  10: "Low Risk",
  35: "Medium Risk",
  75: "High Risk"
};

var lightGray = '#F7F7F7';
export var lineColor = "#999492"
export var riskZoneLineColor = "#dadada"
export var primaryGraphColor = "#424343"
export var crosshairColor = "rgba(209, 236, 255, .66)"

function riskLabeler(crp_value) {
  var riskValues = {
    low: {
      value: riskThresholds.low,
      text: "Low Risk"
    },
    medium: {
      value: riskThresholds.medium,
      text: "Medium Risk"
    },
    high: {
      value: riskThresholds.high,
      text: "High Risk"
    }
  };
  if (crp_value >= riskValues.high.value) {
    return riskValues.high.text;
  } else if (crp_value >= riskValues.medium.value) {
    return riskValues.medium.text;
  } else {
    return riskValues.low.text;
  }
};

export function portfolioColumnTooltipFormatter(column) {
  let i = column.series.data.indexOf(column.point)
  let risk_column = column.series.chart.series[1].points[i]
  return '<span>' + column.key + "</span><br/>" +
    '<span style="color:' + risk_column.color + '">\u25CF</span><b> ' +
    riskLabeler(risk_column.y) + "</b><br/>"
};

export function columnTooltipFormatter(column, resourceNames, comparativeCrpData, scores) {
  var i = column.series.data.indexOf(column.point)
  var risk_column = column.series.chart.series[1].points[i]
  var bullet = '<span>\u25CF</span><b>'
  var score = bullet + '<span>' + ' Score: ' + (100 - Math.round(scores[i])) + "</span><br/>"
  var averageScore = comparativeCrpData ? '<span>\u25CF</span><b>' + '<span>' +
    ' Average Score: ' + (100 - Math.round(comparativeCrpData[i])) + "</span><br/>" : ''
  return '<span>' + resourceNames[i] + "</span><br/>" +
    '<span style="color:' + risk_column.color + '">\u25CF</span><b> ' +
    riskLabeler(risk_column.y) + "</b><br/>" + score + averageScore

};

export function controlCategoryDataLabelFormatter(series, average_score) {
  var average_score_text = average_score == false ? '' :
    "<span style='color: #999;font-size: .8em;font-weight: bold;'>AVG: " +
    average_score + "%</span>"
  return `
    <div class='text-gray-700 text-center whitespace-normal'>
      <div class='font-semibold text-xl'>
        ${series.y}%
      </div>
      <div class='text-lg'>controls complete</div>
      <div>${average_score_text}</div>
    </div>
  `
}

var riskGaugeOptions = []
for (var key in riskColorRangeRedToGreen) {
  riskGaugeOptions.push({ y: 10, name: "", color: riskColorRangeRedToGreen[key] })
};

export function positionDial(crp_value) {
  return (crp_value / 100) * 90;
};

function animationState() {
  return false;
};

function setLabelFontSize() {
  return "10.5px"
};

export var chartFont = "helvetica";
export var chartBackgroundColor = null;
export var xAxisTickWidth = 0;
export var labelFontSize = setLabelFontSize();
export var labelTextOverflow = "none";

export var crpBarChartYAxisOptions = {
  plotBands: [{
    color: riskZoneLineColor,
    from: 19.8,
    to: 20.1
  }, {
    color: riskZoneLineColor,
    from: 49.8,
    to: 50.1
  }],
  lineWidth: 1,
  lineColor: lineColor,
  gridLineWidth: 0,
  gridLineColor: lineColor,
  opposite: true,
  tickPositions: [-2, 0, 10, 35, 75, 100],
  title: {
    text: ""
  },
  labels: {
    style: {
      color: primaryGraphColor,
      fontSize: labelFontSize,
    },
    formatter: function () {
      var value = riskLabels[this.value];
      return value !== "undefined" ? value : this.value;
    }
  },
}

export var xAxisLabelStyle = {
  fontSize: labelFontSize,
  color: primaryGraphColor,
  textOverflow: labelTextOverflow,
}

export var plotOptionsBarBorderColor = null;
export var plotOptionsBarBorderWidth = 0;
export var plotOptionsBarGrouping = false;
export var plotOptionsBarShowInLegend = false;
export var plotOptionsBarThreshold = -2;
export var plotOptionsBarZones = riskZonesGreenToRed;
export var barChartBorderRadius = 3;

Highcharts.setOptions({
  chart: {
    style: {
      fontFamily: chartFont,
    }
  },
  credits: {
    enabled: false
  },
});
